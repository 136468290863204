








































































































































































































































































































































































































@import '~@/assets/styles/components/formularios';
@import '~@/assets/styles/components/modal-cadastro';

.box-table {
  .cancelado {
    color: #F00;
  }

  &-row {
    cursor: pointer;
  }
}
